.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em 1em 1em;
  background-color: var(--bg-color);
  color: var(--text-color);
  position: fixed;
  box-shadow: 0 0.125em 0.625em rgba(0, 0, 0, 0.2);
    display: flex
;
  width: 100%;
  z-index: 2;
}

.css-28sncy-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected{
  color: var(--text-color) !important ;
}

.css-28sncy-MuiButtonBase-root-MuiBottomNavigationAction-root{
  color: var(--text-color) !important ;

}

.css-lrvwdm-MuiBottomNavigation-root{
  box-shadow: var(--box-shadow) !important;
}



.css-1j3pwd3-MuiSvgIcon-root{
  fill: var(--text-color) !important;
}

.css-1toxriw-MuiList-root-MuiMenu-list{
  padding: 0 !important;
  display: flex;
}



.MuiPaper-root{
  top: 82% !important;
}
.dropdown {
  position: relative; /* Position the dropdown relative to the parent */
}

.dropdown-menu {
  display: none; /* Initially hide the dropdown */
  position: absolute; /* Position the dropdown relative to its parent */
  top: 100%; /* Align dropdown directly below the parent */
  left: 0;
  box-shadow: var(--box-shadow); /* Add shadow for visibility */
  z-index: 10; /* Ensure it's above other elements */
  list-style: none;
  padding: 0;
  margin: 0; /* Remove default margin */
  min-width: 150px; /* Width of dropdown */
}

.css-1scjuq2-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
  color: var(--text-color) !important;
}

.css-1093voi-MuiButtonBase-root-MuiBottomNavigationAction-root{
  padding: 0px 6px !important;
  min-width: 63px !important;
  padding-top: 14px !important;
}

.css-1rju2q6-MuiButtonBase-root-MuiMenuItem-root{
  font-size: 12px !important;
}

.dropdown:hover .dropdown-menu,
.dropdown-menu:hover {
  display: block; /* Keep dropdown visible on hover */
}

.dropdown-menu li {
  padding: 10px 15px; /* Add padding for dropdown items */
  cursor: pointer;
}

.dropdown-menu li:hover {
  color: #f0b90b;/* Highlight dropdown items on hover */
}



.order-dropdown {
  position: relative; /* Position the dropdown relative to the parent */
}

.order-dropdown-menu {
  display: none; /* Initially hide the dropdown */
  position: absolute; /* Position the dropdown menu relative to the parent */
  top: 100%; /* Align dropdown below the parent */
  left: 0;
  background-color: var(--bg-color); /* Background color */
  box-shadow: var(--box-shadow); /* Add shadow for visibility */
  list-style: none;
  padding: 0;
  border-radius: 0.5em;
  margin: 0;
  opacity: 0; /* Start with invisible state */
  transform: translateY(-10px); /* Start with a slight upward offset */
  transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth animation */
  z-index: 10; /* Ensure dropdown appears above other elements */
  min-width: 150px; /* Minimum width for dropdown */
}

.navbar-links >li{
  padding: 0.3em;
 
}

.order-dropdown:hover .order-dropdown-menu {
  display: block; /* Make dropdown visible */
  opacity: 1; /* Fully visible */
  transform: translateY(0); /* Reset position */
}

.order-dropdown-menu li {
  padding: 10px 15px; /* Add padding for dropdown items */
  cursor: pointer;
  text-align: start;
}

@media (min-width:781px) {
  .css-1j5cws8-MuiBottomNavigation-root{
    display: none !important;
  }
  .bottomNavbar{
    display: none; 
   }
}

.order-dropdown-menu li:hover {
  background-color: var(--bg-color); /* Highlight dropdown items on hover */
  color: #f0b90b;
}


/* Active dropdown item */
.dropdown .dropdown-toggle.active::after {
  content: "";
  position: absolute;
  bottom: -5px; /* Position the underline slightly below the text */
  left: 40%;
  transform: translateX(-50%);
  width: 50%; /* Adjust width for a shorter underline */
  height: 3px;
  background-color: #f0b90b; /* Color for the active dropdown underline */
  transition: width 0.3s ease-in-out; /* Smooth transition */
}

.bottomNavbar{
 display: block; 
}

/* .css-1xvz2vb-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected{color:var(--text-color) !important;} */
.dropdown .dropdown-toggle.active:hover::after {
  width: 100%; 
}

/* .css-1j5cws8-MuiBottomNavigation-root{
  height: 60px !important;
} */

/* Active dropdown menu item */
.dropdown-menu li.active a::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 3px;
  background-color: #f0b90b;
  transition: width 0.3s ease-in-out;
}

/* Smooth animation for dropdown menu item on hover */
.dropdown-menu li.active a:hover::after {
  width: 100%; /* Full width on hover */
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-icon {
  margin-left: 8px;
  font-size: 12px;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.dropdown.open .dropdown-icon {
  transform: rotate(180deg); /* Rotate icon when dropdown is open */
}

.icon-container {
  position: relative; /* Required for positioning the tooltip */
  display: inline-block; /* Allows the tooltip to position correctly */
  margin-right: 10px; /* Space between icons */
}

.tooltip {
  visibility: hidden; /* Hidden by default */
  width: 70px; /* Adjust width as needed */
  background-color: black; /* Background color of the tooltip */
  color: #fff; /* Text color */
  text-align: center; /* Center the text */
  border-radius: 5px; /* Rounded corners */
  padding: 5px; /* Padding around text */
  position: absolute; /* Positioning */
  z-index: 1; /* Ensure the tooltip appears above other elements */
  bottom: 100%; /* Position the tooltip above the icon */
  left: 50%; /* Center the tooltip */
  margin-left: -35px; /* Offset for center alignment */
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s; /* Fade-in effect */
}

.icon-container:hover .tooltip {
  visibility: visible; /* Show the tooltip on hover */
  opacity: 1; /* Fade in */
}

.navbar-icons {
  display: flex;
  align-items: center;
}

.hamburger {
  display: none;
}
.navbar-logo img {
  width: 4.25em;
  height: 3.5em;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 2em;
  margin-bottom: 0;
  z-index: 100;
}

.navbar-links li a {
  text-decoration: none;
  color: var(--text-color);
  position: relative;
  transition: color 0.3s ease-in-out; /* Add color transition */
}

/* Hover effect */
.navbar-links li a:hover {
  color: #f0b90b;
}

/* Active link with ::after pseudo-element for the underline effect */
.navbar-links li a.active::after {
  content: "";
  position: absolute;
  bottom: -5px; /* Position the underline slightly below the text */
  left: 50%;
  transform: translateX(-50%);
  width: 50%; /* Adjust width for a shorter underline */
  height: 3px;
  background-color: #f0b90b; /* Color for the active link underline */
  transition: width 0.3s ease-in-out; /* Smooth transition when becoming active */
}

/* Adds smooth animation when changing the width of the underline */
.navbar-links li a.active:hover::after {
  width: 100%; /* Full width on hover */
}
.navbar-links li div.active:hover::after {
  width: 100%; /* Full width on hover */
}

.navbar-icons {
  display: flex;
  align-items: center;
  gap: 1em;
}

.icon {
  font-size: 1.25em;
  cursor: pointer;
}

button {
  background: none;
  border: none;
  font-size: 1.25em;
  cursor: pointer;
}

.dark {
  --bg-color: #1a1a1a;
  --text-color: white;
}

.light {
  --bg-color: white;
  --text-color: black;
}

/* --- Mobile Responsiveness --- */
@media (max-width: 780px) {
  .navbar-links {
    position: absolute;
    top: 100%;
    height: 100vh;
    left: 0;
    right: 0;
    background-color: var(--bg-color);
    flex-direction: column;
    gap: 0;
    max-height: 0; /* Initially hidden */
    overflow: hidden;
    display: flex;
    transition: max-height 0.3s ease-in-out; /* Transition for smooth open/close */
  }

  .dropdown .dropdown-toggle.active::after {
    content: "";
    position: absolute;
    bottom: -5px; /* Position the underline slightly below the text */
    left: 47.8%;
    transform: translateX(-50%);
    width: 10.2%; /* Adjust width for a shorter underline */
    height: 3px;
    background-color: #f0b90b; /* Color for the active dropdown underline */
    transition: width 0.3s ease-in-out; /* Smooth transition */
  }

  .dropdown .dropdown-toggle.active:hover::after {
    width: 20%; /* Full width on hover */
  }

  /* When active, set a larger max-height to accommodate all items */
  .navbar-links.active {
    max-height: 100vh; /* Adjust this value depending on your menu's height */
  }

  .navbar-links li {
    text-align: center;
    padding: 1em 0;
    border-bottom: 1px solid #ccc;
  }

  .navbar-links li:last-child {
    border-bottom: none;
  }

  .navbar-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /* Shows the hamburger only on smaller screens */
  .hamburger {
    display: block;
    font-size: 1.5em;
    cursor: pointer;
  }
}

@media (max-width: 480px) {
  .navbar-links {
    height: 100vh;
  }
  .navbar-logo img {
    width: 3.5em;
    height: 3em;
  }
}

/* Styles for the dropdown */
.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  background-color: var(--bg-color) !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0;
  list-style: none;
  display: none;
  flex-direction: column;
}

.dropdown-menu li {
  padding: 10px 20px;
  color: var(--text-color);
}

.dropdown-menu li a {
  text-decoration: none;
  color: var(--text-color);
}

/* .dropdown-menu li a:hover {
  background-color: #f0f0f0;
} */
