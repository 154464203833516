.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center; /* Center the cards horizontally */
}

.marketPlace{
  width: 100%;
  margin-top: 7em;
  position: absolute;
}

/* Style for the normal subscribe button */
.subscribe-btn {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.deploy-btn {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Style for hover effect */
.subscribe-btn:hover {
  background-color: #0056b3;
}
.deploy-btn:hover {
  background-color: #0056b3;
}

/* Style for the disabled button */
.subscribe-btn:disabled {
  background-color: #cccccc; /* Lighter gray to indicate disabled state */
  color: #666666;
  cursor: not-allowed;
  opacity: 0.7;
}
.deploy-btn:disabled {
  background-color: #cccccc; /* Lighter gray to indicate disabled state */
  color: #666666;
  cursor: not-allowed;
  opacity: 0.7;
}

.card {
  margin-top: 2em;
  width: 22em;
  border-radius: 0.625em;
  padding: 1em;
  background-color: var(--bg-color);
  box-shadow:var(--box-shadow);
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column; /* Ensures vertical layout */
  justify-content: space-between; /* Keeps footer at the bottom */
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  text-align: left;
}

.strategy-icon {
  width: 2em;
  height: 2em;
  margin-right: 0.5em;
}

.strategy-details h2 {
  margin: 0;
  color: var(--text-color);
  font-size: 1.025em;
  font-weight: bold;
}

.strategy-type {
  margin-bottom: 0.3em;
  font-size: 0.65em;
  color: var(--text-color);
}

.premium-badge {
  background-color: #007bff;
  color: var(--text-color);
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  font-size: 0.75em;
}

.capital-info {
  padding: 0.3em;
  display: flex;
  text-align: left;
  margin: 1em 0;
  font-size: 0.8em;
  color: var(--text-color);
}

.strategy-info {
  padding: 0.3em;
  text-align: left;
  font-size: 0.8em;
  color: var(--text-color);
  flex-grow: 1; /* Allows content to grow and fill space */
}

.execution-info {
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
  flex-direction: column;
  gap: 0.8em;
  flex-grow: 0; /* Prevents this from expanding */
}

.time-info,
.days-info,
.subscriber-info,
.deployed-info,
.created-by-info,
.creation-date-info {
  padding: 0.3em;
  width: 100%;
  border-radius: 0.5em;
  display: flex;
  gap: 1em;
  align-items: center;
  text-align: left;
  background-color: var(--bg-color);
  font-size: 0.65em;
  color: var(--text-color);
  box-shadow: 0 0.125em 0.5em rgba(0, 0, 0, 0.2);
  word-break: break-all;
  font-weight: bold;
}

.divider {
  border-top: 0.0625em solid #ddd;
  margin: 1em 0;
}

.card-footer {
  display: flex;
  gap: 1em;
  padding: 0.8em 0;
  justify-content: space-between;
  flex-shrink: 0; /* Keeps footer from shrinking */
}

.additional-info {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

.subscribe-btn,
.deploy-btn {
  padding: 0.5em 1em;
  width: 100%;
  font-size: 0.875em;
  border: none;
  border-radius: 0.25em;
  cursor: pointer;
}

.subscribe-btn {
  width: 100%;
  background-color: var(--opo-bg-color);
  color: var(--opo-text-color);
}
/* .deploy-btn {
  width: 100%;
  background-color: var(--opo-bg-color);
  color: var(--opo-text-color);
} */

.deploy-btn {
  background-color: transparent;
  color: var(--opo-bg-color);
  border: 0.0625em solid var(--opo-bg-color);
}
