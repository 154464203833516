.list-no-style {
  list-style-type: none;
}
.qwe {
  display: flex;
  align-content: flex-end;
}

.deskbord {
  padding: 0 1.5em;
}

.container.deskbord {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.hjg.gfhglio {
  flex: 1;
      display: flex
;
    justify-content: center;
}

.bottom-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: none;
}

@media (max-width:876px) {
  .bottom-navbar {
    display: block !important;
  }
}

.css-1azmsd1-MuiBottomNavigation-root{
  width: 100% !important;
  background-color: var(--bg-color) !important;
  background: var(--bg-color) !important;
}

.css-d80rna-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
  color: #FFA500 !important;
}

.yqrgk {
  background-color: var(--bg-color);
  border-radius: 0.5em;
  box-shadow: var(--box-shadow);
  margin-top: 6em;
}

.jahgs {
  margin-top: 3em;
  border-radius: 1em;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.OrderPlace {
  margin-top: 3em;
  border-radius: 1em;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  padding: 1em;
}

.nays {
  padding: 0.9em;
  margin-left: 0em;
  margin-top: 2em;
  border-radius: 1em;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.agvs {
  border-radius: 1em;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.green {
  color: lime;
}

.red {
  color: red;
}

/* .sc-beySPh{
    margin-left: 78px;
} */

.qscv {
  margin-left: -10pxpx !important;
}

/* dark */

.cdaacceecaec {
  margin-top: 3em;
  border-radius: 1em;
  background-color: #161a1d;
  box-shadow: 1px 1px 1px rgba(200, 200, 200, 0.1),
    1px 1px 1px rgba(70, 70, 70, 0.1);
}

.vgjsdbhcd {
  background-color: #161a1d;
  border-radius: 1em;
}

@media (max-width: 30em) {
  .yqrgk {
  margin-top: 5em;
    margin-left: 0.1em;
    margin-right: 0.1em;
  }
  
}
