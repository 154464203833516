.App {
  text-align: center;
}

.bg {
  position: relative;
  width: 94.9em;
  height: 45em;
}

body {
  user-select: none;
}


table.table-bordered > thead > tr > th,
table.table-bordered > tbody > tr > td {
  border: none;
}

/*dark */

.dark-theme {
  text-align: center;
  background-color: #101214; /* Dark background color */
  color: #ffffff; /* Light text color */
}

.aaa {
  background-color: blue;
}

.App {
  background-color: var(--bg-color);
  color: var(--text-color);
  min-height: 100vh;
}

.app {
  background-color: var(--bg-color);
  color: var(--text-color);
}

.dark {
  --bg-color: #101013 !important;
  --text-color: white !important;
  --opo-bg-color: white !important;
  --opo-text-color: black !important;
  --border-color: white !important;
  --box-shadow: 0 0.125em 0.625em rgba(0, 0, 0,0.8)
}

.light {
  --bg-color: white;
  --opo-bg-color: #1a1a1a !important;
  --opo-text-color: white !important;
  --text-color: black;
  --border-color: black !important;
  --box-shadow: 0 0.125em 0.625em rgba(0, 0, 0,0.2)

}
