/* General styling for stats card */
.stats-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* Ensures even spacing between columns */
  padding: 1.25em;
  /* 20px becomes approximately 1.25em */
  background-color: var(--bg-color);
  border-radius: 0.5em;
  /* 8px becomes approximately 0.5em */
  box-shadow: var(--box-shadow);
  /* 2px becomes 0.125em and 10px becomes 0.625em */
}



.deployed-margin{
  margin-top: 0em !important;
}

.Deployed{
  width: 100%;
  position: absolute;
}

.mobile-account-item{
  display: none
}

/* From Uiverse.io by vinodjangid07 */ 
.Btn {
  width: 45px !important;
  height: 45px;
  border-radius: 15px;
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.11);
}

.svgIcon {
  fill: var(--text-color);
}

.icon2 {
  width: 18px;
  height: 5px;
  border-bottom: 2px solid var(--text-color);
  border-left: 2px solid var(--text-color);
  border-right: 2px solid var(--text-color);
}

.Btn:hover {
  background-color: var(--bg-color);
  transition-duration: 0.3s;
}

.Btn:hover .icon2 {
  border-bottom: 2px solid var(--text-color);
  border-left: 2px solid var(--text-color);
  border-right: 2px solid var(--text-color);
}

.Btn:hover .svgIcon {
  fill: var(--text-color);
  animation: slide-in-top 1s linear infinite;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}



.qwmz{
  color: var(--text-color);
}

/* Container for Calendar and Charts */
.calendar-stats-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; /* Center vertically */
  gap: 20px; /* Add spacing between items */
}

.horizontal-line {
  border: none;           /* Remove default border */
  border-top: 1px solid var(--text-color); /* Thin solid line with light gray color */
  margin: 10px 0;         /* Add spacing above and below the line */
  width: 100%;            /* Full width */
}



/* .calendar-cell.selected {
  outline: 2px solid var(--text-color);
} */
.calendar-body :hover {
  outline: 2px solid var(--text-color);
}
.calendar-cell :hover {
  outline: none;
}

.days-of-cal:hover{
  outline: 1px solid var(--text-color);

}
.month-dropdown{
  background-color: transparent;
  color: var(--text-color);
  border-radius: 0.3em;
  padding: 0px 0.5em;
  border-radius: 0.4em;
  background-color: transparent;
  color: var(--text-color);
}

.dropdown-container{
  gap: 0.5em;
  display: flex
}


/* Calendar Section (Left Side) */

/* Charts Section (Right Side) */
.charts-section {
  display: flex;
  gap: 15px; /* Space between charts */
  justify-content: center;
  align-items: center;
}


.stats-toggle-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.qzptd {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.Strategy-details {
  width: 100%;
  display: flex;
  flex-direction: row;
}



/* Individual stat item */
.stat-item {
  width: 22%;
  /* Stat items arranged in 4 columns by default */
  margin-bottom: 1.25em;
  /* 20px becomes approximately 1.25em */
  min-width: 7.5em;
  /* 120px becomes approximately 7.5em */
  text-align: left;
}

/* Label and value styling for stats */
.label {
  font-weight: bold;
  font-size: 1em;
  /* Default font size */
  color: #777;
  margin-bottom: 0.3125em;
  /* 5px becomes approximately 0.3125em */
}

.value {
  font-size: 0.8em;
  font-weight: bold;
}

/* Green and red text for values */
.green {
  color: #4caf50;
}

.red {
  color: #f44336;
}

/* .active {
  color: #4caf50;
} */

/* Container styling */
.stats-container {
  background-color: var(--bg-color);
  padding: 1.25em;
  border-radius: 0.5em;
  box-shadow: var(--box-shadow);
  margin-top: 1em;
  /* 10px becomes approximately 0.625em */
}

/* Account information styling */
.account-info {
  margin-right: 0.5em;
  align-items: center;
  margin-left: 0.5em;
  display: flex;

  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 0.8em;
  /* 8px becomes approximately 0.5em */
}

.account-item .label {
  font-size: 1em;
  color: #777;
  margin-right: 0.5em;
  /* 8px becomes approximately 0.5em */
}

.account-item .value {
  font-size: 1em;
  font-weight: bold;
}

/* Styling for the toggle switch container */
.toggle-switch {
  display: inline-flex;
  align-items: center;
  width: 3.75em;
  /* 60px becomes approximately 3.75em */
  height: 1.875em;
  /* 30px becomes approximately 1.875em */
  background-color: #ddd;
  border-radius: 3.125em;
  /* 50px becomes approximately 3.125em */
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

.toggle-switch.on {
  background-color: #4caf50;
}

.toggle-switch.off {
  background-color: #f44336;
}

/* Circle inside the toggle switch */
.toggle-circle {
  width: 1.75em;
  /* 28px becomes approximately 1.75em */
  height: 1.75em;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 0.0625em;
  /* 1px becomes approximately 0.0625em */
  left: 0.0625em;
  transition: left 0.3s ease;
}

.toggle-switch.on .toggle-circle {
  left: 1.9375em;
  /* 31px becomes approximately 1.9375em */
}

/* Toggle label, optional */
.toggle-label {
  margin-left: 2.5em;
  /* 40px becomes approximately 2.5em */
  font-size: 0.875em;
  /* 14px becomes approximately 0.875em */
  color: white;
}

/* Toggle Switch Styling */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 50px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

/* Modal Styles */
.modal-overlay {
  background: rgba(0, 0, 0, 0.5); /* Transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.confirm-modal {
  background: var(--bg-color);
  border-radius: 10px;
  padding: 30px;
  max-width: 400px;
  width: 100%;
  color: var(--text-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.month-dropdown option {
  background-color: var(--bg-color); /* Background color for options */
  color: var(--text-color); /* Text color for options */
  padding: 10px;
}

.pnl-details {
  font-size: 16px;
  text-align: start;
}

.date {
  font-size: 15px;
  color: var(--text-color); /* Blue color for the date */
  margin-bottom: 5px;
}

.pnl-value {
  font-size: 15px;
}

.label {
  font-weight: bold;
}

.value {
  font-size: 15px;
}

.value.negative {
  color: #f44336; /* Red color for negative P&L */
}
.value.positive {
  color: #40ff3d; /* Red color for negative P&L */
}

/* Hover effect for options */
.month-dropdown option:hover {
  background-color: #ddd; /* Background color on hover */
}

.modal-content h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.modal-content p {
  font-size: 16px;
  color: var(--text-color);

  margin-bottom: 20px;
}

.modal-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.cancel-btn,
.delete-btn {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-btn {
  background-color: #f0f0f0;
  color: #555;
}

.delete-btn {
  background-color: #f44336;
  color: white;
}

.cancel-btn:hover,
.delete-btn:hover {
  opacity: 0.8;
}

.wwwwsssssdddd{
  display: none;
}

/* From Uiverse.io by santhoshsj-dev */ 
.bg {
  padding: 20px;
  border-radius: 10px;
  background-color: var(--base-color);
  --base-color: #212121;
 /*use your base color*/
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.process {
  width: 60px;
  height: 40px;
  position: relative;
  display: inline-block;
  background-color: var(--base-color);
}

.process::before {
  content: '';
  left: 0;
  top: 0;
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #FFF;
  background-image: radial-gradient(circle 8px at 18px 18px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 18px 0px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 0px 18px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 36px 18px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 18px 36px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 30px 5px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 30px 5px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 30px 30px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 5px 30px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 5px 5px, var(--base-color) 100%, transparent 0);
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: rotationBack 3s linear infinite;
}

.process::after {
  content: '';
  left: 35px;
  top: 15px;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #FFF;
  background-image: radial-gradient(circle 5px at 12px 12px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 12px 0px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 0px 12px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 24px 12px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 12px 24px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 20px 3px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 20px 3px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 20px 20px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 3px 20px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 3px 3px, var(--base-color) 100%, transparent 0);
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: rotationBack 4s linear infinite reverse;
}

.edcvon{
  width: 100%;
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}  

.month-year-drop{
  padding-left: 15em;
  margin-top: -1.55em;
}


@media (max-width:64em) {
  .qpmezk{
    flex-direction: column;
    }
    .userid-brokerid{
      width: 100% !important;
      justify-content: center !important;
    }
}

/* Responsive adjustments */
@media (max-width: 48em) {
  /* 768px becomes 48em */
 
  .broker-btns{
    gap: 0 !important;
    flex-direction: column !important;
  }
  .userid-brokerid{
    width: 100% !important;
    justify-content: start !important;
  }
  .account-item {
    flex-direction: column;
    text-align: start;
  }

 

  .label,
  .value {
    width: 100%; /* Take up full width in column layout */
    text-align: left;
    margin-bottom: 0.3125em; /* 5px = 0.3125em */
  }
  .date-months{
    
  }

  .account-item-none{
    display: none;
  }

  .mobile-account-item{
    display: flex;
    margin-top: 1em;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .wwwwsssssdddd{
    display: block;
  }
  .udfhdjbnns {
    flex-direction: column;
    gap: 1em;
  }

  .eeerrtt{
    flex-direction: column;
    gap: 0px !important;
    margin-bottom: 1em;
  }

  .horizontal-line{
    display: none;
  }

  .edcvon{
    border-bottom: none !important;
  }

  .dropdown-content{
    align-items: start;
    display: flex;
    flex-direction: column;
  }

  .account-info {
    margin-right: 0.5em;
    align-items: start;
    margin-left: 0.5em;
    display: flex;
    gap: 0px;
    flex-direction: column;
}

  .nnnnmm{

    display: none;
  }

  .qzptd{
    flex-direction: column;
  }
  .stat-item {
    width: 48%;
    /* Two columns on medium screens */
  }

  .toggle-switch {
    width: 3.125em;
    /* 50px becomes approximately 3.125em */
    height: 1.5625em;
    /* 25px becomes approximately 1.5625em */
  }

  .toggle-circle {
    width: 1.5em;
    /* 24px becomes approximately 1.5em */
    height: 1.5em;
  }

  .toggle-switch.on .toggle-circle {
    left: 1.5625em;
    /* 25px becomes approximately 1.5625em */
  }
}

@media (max-width: 30em) {

  .canedvj{
    flex-direction: row !important;
  }

  .month-year-drop{
    padding-left: 0em;
    margin-top: 1em;
  }
  /* 480px becomes 30em */
  .stat-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* Full width on small screens */
    margin-bottom: 0.5em;
    border-bottom: 0.05em solid grey;
  }

  .stats-container {
    margin: 0.625em 0em;
    padding: 1.25em 0em;
  }

  .toggle-switch {
    width: 2.5em;
    /* 40px becomes approximately 2.5em */
    height: 1.25em;
    /* 20px becomes approximately 1.25em */
  }

  .toggle-circle {
    width: 1.125em;
    /* 18px becomes approximately 1.125em */
    height: 1.125em;
  }

  .toggle-switch.on .toggle-circle {
    left: 1.25em;
    /* 20px becomes approximately 1.25em */
  }

  .toggle-label {
    font-size: 0.75em;
    /* 12px becomes approximately 0.75em */
    margin-left: 1.875em;
    /* 30px becomes approximately 1.875em */
  }
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--text-color);
  color: var(--text-color);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--text-color);
  color: var(--text-color);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--text-color);
  color: var(--text-color);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: var(--text-color);
  }
  50%, 100% {
    background-color: rgba(128, 128, 128, 0.621);
  }
}