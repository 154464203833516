.animatedbounceInDown {
    animation: bounceInDown 1s;
    -webkit-animation: bounceInDown 1s;
    /* padding: 20px 0; */
    width: 52%;
    /* margin-top: 25em; */
    max-width: 1200px;
    /* margin: 7em auto; */
}

@keyframes bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0);
    }

    75% {
        transform: translate3d(0, -10px, 0);
    }

    90% {
        transform: translate3d(0, 5px, 0);
    }

    to {
        transform: none;
    }
}

.main-container {
    display: flex;
    position: absolute;
    margin-top: -50em;
    justify-content: space-between;
    align-items: stretch; /* Ensures both elements stretch vertically */
    left: 33em;
}

.jshvdjsdvjhsdvjh {
    flex: 1 1;
    max-width: 400px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-right: 20px;
}

.side-image-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.side-image {
    width: 100%; /* Adjust image width to fill container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.error {
    color: red;
    font-size: 14px;
}

.box {
    display: flex;
    flex-direction: column;
}

.box label {
    font-weight: bold;
    margin-bottom: 32px;
    font-size: 18px;
    color: #333;
    margin-top: 25px;
}

.box input {
    margin-bottom: 15px;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #ddd;
    font-size: 16px;
    transition: border-color 0.3s;
}

.box input:focus {
    border-bottom-color: #007bff;
    outline: none;
}

.btn1 {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn1:hover {
    background-color: #0056b3;
}

.btn1:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.tada {
    animation: tada 1s;
}

@keyframes tada {
    from {
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}

.hasbdhjasdahjhjhj{
    text-align: center;
}