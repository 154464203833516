.black-friday-component {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding-inline: 2em;
    width: 50%;
  }
  .black-friday-component .coupon {
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    border: 1px solid #654bff;
    scale: 0.7;
    transform-origin: bottom center;
  }
  .black-friday-component .coupon:hover .content h3 > span {
    animation: jump 0.15s ease-out 1 normal both;
  }
  .black-friday-component .coupon:hover .content h3 > span:nth-of-type(1) {
    animation-delay: 0.03s;
  }
  .black-friday-component .coupon:hover .content h3 > span:nth-of-type(2) {
    animation-delay: 0.06s;
  }
  .black-friday-component .coupon:hover .content h3 > span:nth-of-type(3) {
    animation-delay: 0.09s;
  }
  .black-friday-component .coupon:hover .content h3 > span:nth-of-type(4) {
    animation-delay: 0.12s;
  }
  .black-friday-component .coupon:hover .content h3 > span:nth-of-type(5) {
    animation-delay: 0.15s;
  }
  .black-friday-component .coupon:hover .content h3 > span:nth-of-type(6) {
    animation-delay: 0.18s;
  }
  .black-friday-component .coupon:hover .content h3 > span:nth-of-type(7) {
    animation-delay: 0.21s;
  }
  .black-friday-component .coupon:hover .content h3 > span:nth-of-type(8) {
    animation-delay: 0.24s;
  }
  .black-friday-component .coupon:hover .content h3 > span:nth-of-type(9) {
    animation-delay: 0.27s;
  }
  .black-friday-component .coupon:hover .content h3 > span:nth-of-type(10) {
    animation-delay: 0.3s;
  }
  .black-friday-component .coupon:hover .content h3 > span:nth-of-type(11) {
    animation-delay: 0.33s;
  }
  .black-friday-component .coupon:hover .content h3 > span:nth-of-type(12) {
    animation-delay: 0.36s;
  }
  .black-friday-component .coupon .box {
    height: 100%;
    width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  .black-friday-component .coupon .box span {
    display: block;
    font-weight: bold;
    font-size: 1.8rem;
  }
  .black-friday-component .coupon .diver {
    display: block;
    width: 0px;
    height: 160px;
    border: 3px dashed transparent;
    border-right-color: #654bff;
  }
  .black-friday-component .coupon .content {
    padding: 1rem;
    flex: 1;
    width: auto;
    height: 100%;
  }
  .black-friday-component .coupon .content h3 {
    font-weight: 600;
    font-size: 2rem;
    height: 34px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }
  .black-friday-component .coupon .content h3 span.end {
    width: 30px;
  }
  .black-friday-component .coupon .content {
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .black-friday-component .coupon .content p a {
    font-style: italic;
    color: #654bff;
    text-transform: capitalize;
    word-spacing: 0.03rem;
    font-weight: bolder;
    text-decoration: underline;
    text-underline-offset: 0.05rem;
    text-decoration-color: #654bff;
  }
  .black-friday-component .timer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0rem;
    overflow: hidden;
    width: calc(244px + 0rem);
    text-align: center;
  }
  .black-friday-component .timer .colon {
    width: 8px;
    font-size: 2rem;
  }
  .black-friday-component .timer .item {
    text-align: center;
    width: 55px;
    /* heig; */
  }
  .black-friday-component .timer .item .days,
  .black-friday-component .timer .item .hours,
  .black-friday-component .timer .item .minutes,
  .black-friday-component .timer .item .seconds {
    align-items: center;
    display: flex;
    text-align: center;
    width: 55px;
    font-size: 1.5em;
    justify-content: center;
    overflow: hidden;
    height: 2.2em;
  }
  .black-friday-component .timer .item .day,
  .black-friday-component .timer .item .hour,
  .black-friday-component .timer .item .min,
  .black-friday-component .timer .item .sec {
    text-align: center;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .black-friday-component .timer .item .day span,
  .black-friday-component .timer .item .hour span,
  .black-friday-component .timer .item .min span,
  .black-friday-component .timer .item .sec span {
    height: 40px;
    font-size: 2rem;
    display: inline-block;
  }
  
  .black-friday-component .timer .item .day {
    --count: var(--days);
    animation: slide var(--count) 86400s ease-out 0s 31 normal both; /* 1 day duration */
  }
  .black-friday-component .timer .item .hour {
    --count: var(--hours);
    animation: slide var(--count) 3600s steps(var(--count)) 0s 24 normal both; /* 1 hour duration */
  }
  .black-friday-component .timer .item .min {
    --count: var(--minutes);
    animation: slide var(--count) 60s steps(var(--count)) 0s 60 normal both; /* 1 minute duration */
  }
  .black-friday-component .timer .item .sec {
    --count: var(--seconds);
    animation: slide var(--count) 1s steps(var(--count)) 0s 60 normal both; /* 1 second duration */
  }
  
  .black-friday-component .timer .item p {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
  }
  
  @keyframes slide {
    0% {
      transform: translateY(calc(-40px * var(--count)));
    }
    99.6% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(calc(-40px * var(--count)));
    }
  }
  @keyframes jump {
    0% {
      transform: translateY(0);
      scale: 1;
    }
    50% {
      transform: translateY(-10px);
      scale: 1.1;
    }
    100% {
      transform: translateY(0);
      scale: 1;
    }
  }
  
  .akhsgdyagdsagdjds {
    display: flex;
  }
  
  .ahsgdjasbdashjdas {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1.5em;
  }
  
  .black-friday-component.expired .coupon {
    border: 1px solid gray;
  }
  
  .black-friday-component.expired .coupon .diver {
    display: block;
    width: 0px;
    height: 160px;
    border: 3px dashed transparent;
    border-right-color: gray;
  }
  
  .black-friday-component.expired {
    color: gray;
    text-decoration-color: gray;
    width: 50%;
  }
  
  .hsajavsbdvazmhbhjzdgsvdghsavjda {
    font-size: 2em;
    width: auto;
    margin-bottom: 0.5em;
  }
  
  .askjgdjajdvaghsvdgas {
    font-size: 1em;
    display: -webkit-box; /* Required for line clamping */
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical; /* Required for line clamping */
    overflow: hidden; /* Hides the overflowing text */
    text-overflow: ellipsis; /* Adds the ellipsis */
    word-wrap: break-word; /* Ensures text wraps within the element */
    max-width: 60ch; /* Adjust based on your layout */
  }
  
  .askjgdjajdvaghsvdgas.expired {
    font-size: 1em;
    display: -webkit-box; /* Required for line clamping */
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical; /* Required for line clamping */
    overflow: hidden; /* Hides the overflowing text */
    text-overflow: ellipsis; /* Adds the ellipsis */
    word-wrap: break-word; /* Ensures text wraps within the element */
    max-width: 60ch; /* Adjust based on your layout */
  }
  
  @media (max-width: 1110px) {
    .black-friday-component {
      padding-inline: 0;
    }
    .coupon-message {
      font-size: 1.3rem;
    }
    .hsajavsbdvazmhbhjzdgsvdghsavjda {
      font-size: 1.5em;
    }
    .askjgdjajdvaghsvdgas {
      font-size: 1em;
    }
    .asbdhasbdjgasghvfahdsfah {
      font-size: 0.5em;
    }
  }
  
  @media (max-width: 768px) {
    .coupon-message {
      font-size: 1.1rem; /* Adjust font size for smaller screens */
      padding: 0.8rem;
    }
    .timer {
      font-size: 1em;
    }
    .coupon {
      width: 130%;
    }
  }
  
  @media (max-width: 630px) {
    .akhsgdyagdsagdjds {
      flex-direction: column;
      width: 100%;
    }
    .black-friday-component .coupon {
      width: 130%;
    }
    .black-friday-component {
      width: 100%;
    }
    .black-friday-component.expired {
      width: 100%;
    }
    .timer,
    .item,
    .item p {
      font-size: 1em;
    }
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-container {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    position: relative;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .logo {
    width: 50px;
    margin-bottom: 10px;
  }
  
  .promo-code {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    font-size: 18px;
  }
  
  .promo-code span {
    background: #f0f0f0;
    padding: 10px 20px;
    border: 1px dashed #ccc;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .promo-code button {
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .redeem-button {
    display: inline-block;
    background: #f7c948;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin: 10px 0;
  }
  
  .offer-details {
    font-size: 14px;
    color: #666;
    margin: 10px 0;
  }
  
  .footer {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
  }
  
  .install-button {
    background: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }