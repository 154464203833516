.stepper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 40px 0;
  }
  
  .step {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    color: white;
    font-weight: bold;
    border: 1px solid white;
    transition: background-color 0.8s ease 0.8s, color 0.8s ease 0.8s, border-color 0.5s ease 1.6s; /* Delay border change */
  }
  
  .step.active {
    background-color: #FCD535; /* Active dot color */
    color: black;
    border: 1px solid #FCD535;
    /* transition: border 0.5s ease 0.5s; */
  }

  .step.active::after {
    border-color: #FCD535; /* Change border to yellow after delay */
}

  
  .line {
    flex: 1;
    height: 2px;
    background-color: white;
    margin: 0 10px;
    position: relative;
    overflow: hidden;
  }
  
  .line::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0; /* Start with no fill */
    height: 100%;
    background-color: #FCD535; /* Active line color */
    transition: width 0.8s ease; /* Smooth line animation */
  }
  
  .line.active::before {
    width: 100%; /* Line fully fills */
  }
  