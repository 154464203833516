@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Open+Sans:wght@300;400;600;700&display=swap");

/* .bhttgkl {
  background-color: #1a1a1a;
} */

/* body {
  font-family: "Open Sans", sans-serif;
  color: #0c0c0c;
  background-color: #ffffff;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

.layout_padding,
.layout_padding2,
.layout_padding2-top,
.layout_padding2-bottom,
.layout_padding-top,
.layout_padding-bottom {
  padding: 90px 0;
}

.layout_padding2 {
  padding: 75px 0;
}

.heading_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.asadascascasc {
  font-size: 5vw;
}

.heading_container h2 {
  position: relative;
  font-weight: bold;
  margin-bottom: 0;
}

.heading_container h2 span {
  color: #00bbf0;
}

.heading_container p {
  margin-top: 10px;
  margin-bottom: 0;
}

.heading_container.heading_center {
  align-items: center;
  text-align: center;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  box-shadow: none;
}

.hero_area {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.hero_bg_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  z-index: -1;
}

.hero_bg_box img {
  min-width: 100%;
  min-height: 100%;
}

.bg_img {
  min-width: 60em;
}

.sub_page .hero_area {
  min-height: auto;
  background: linear-gradient(130deg, #231a6f, #0f054c);
}

.sub_page .hero_bg_box {
  display: none;
}

.header_section {
  padding: 15px 0;
}

.header_section .container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}

.navbar-brand span {
  font-weight: bold;
  font-size: 24px;
  color: #ffffff;
}

.custom_nav-container {
  padding: 0;
}

.custom_nav-container .navbar-nav {
  margin-left: auto;
}

.custom_nav-container .navbar-nav .nav-item .nav-link {
  padding: 5px 20px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  transition: all 0.3s;
}

.custom_nav-container .navbar-nav .nav-item.active .nav-link {
  font-weight: 600;
}

.custom_nav-container .nav_search-btn {
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
  color: #ffffff;
}

.custom_nav-container .navbar-toggler {
  outline: none;
  padding: 0;
  width: 37px;
  height: 42px;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span {
  display: block;
  width: 35px;
  height: 4px;
  background-color: #ffffff;
  margin: 7px 0;
  position: relative;
  border-radius: 5px;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::before,
.custom_nav-container .navbar-toggler span::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  top: -10px;
  border-radius: 5px;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::after {
  top: 10px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] {
  transform: rotate(360deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span {
  transform: rotate(45deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span::before,
.custom_nav-container .navbar-toggler[aria-expanded="true"] span::after {
  transform: rotate(90deg);
  top: 0;
}

.slider_section {
  display: contents;
  align-items: center;
  position: absolute;
  padding: 45px 0 145px 0;
}

.slider_section .row {
  align-items: center;
}

.slider_section .detail-box {
  margin-right: 0em;
  color: #00204a;
}

.slider_section .detail-box h1 {
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #ffffff;
}

.slider_section .detail-box p {
  color: #fefefe;
  font-size: 14px;
}

.slider_section .detail-box .btn-box {
  display: flex;
  margin: 0 -5px;
  margin-top: 25px;
}

.slider_section .detail-box .btn-box a {
  margin: 5px;
  text-align: center;
  width: 165px;
}

.slider_section .detail-box .btn-box .btn1 {
  display: inline-block;
  padding: 10px 15px;
  background-color: #00bbf0;
  color: #ffffff;
  transition: all 0.3s;
  border: none;
}

.btn-box {
  justify-content: center;
}

.slider_section .img-box {
  margin: 45px 0;
}

.slider_section .img-box img {
  width: 100%;
  animation: upDown 5s infinite;
}

@keyframes upDown {
  0% {
    transform: translateY(-45px);
  }
  50% {
    transform: translateY(45px);
  }
  100% {
    transform: translateY(-45px);
  }
}

.slider_section .carousel-indicators {
  position: unset;
  margin: 0;
  justify-content: center;
  align-items: center;
}

.slider_section .carousel-indicators li {
  background-color: #ffffff;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  opacity: 1;
}

.slider_section .carousel-indicators li.active {
  width: 20px;
  height: 20px;
  background-color: #00bbf0;
}

/* Add other sections CSS below */
