/* Base styles */
.signin-container {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-color: #1a1a1a;
}

.signin-login-box {
  width: 28em;
  padding: 0em 1.75em 1.75em 1.75em;
  box-shadow: var(--box-shadow);
  background-color: #1a1a1a;
  border-radius: 0.5em;
  text-align: start;
  color: #ffffff;
  margin: auto 5% auto auto; /* Center align for smaller screens */
}

.jsvdfgsvdhfvsdhgvf {
  color: #FCD535;
  font-size: 1em;
  text-align: right;
  margin-top: 0%;
  margin-bottom: 1em;
}

h2 {
  font-size: 1.5em;
}

.ranpq {
  margin-bottom: 1.5em;
  color: #b0b3c7;
  font-size: 1em;
}

form {
  display: flex;
  flex-direction: column;
}

/* Input fields */
label {
  font-size: 0.9em;
  color: #b0b3c7;
  margin-bottom: 0.3em;
}

.signin-input {
  width: 100%;
  max-width: 100%; /* Keep it from resizing in responsive layouts */
  padding: 0.8em;
  border-radius: 0.45em;
  border: 0.08em solid #3b3e57;
  background-color: transparent;
  color: #ffffff;
  font-size: 0.85em;
  margin-bottom: 0.8em;
}

/* Other elements */
.signin-password-container {
  position: relative;
}

.show-password {
  position: absolute;
  right: 0.9em;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #b0b3c7;
  font-size: 0.85em;
  cursor: pointer;
}

.signin-options {
  display: flex;
  justify-content: end;
  font-size: 0.85em;
  color: #b0b3c7;
}

.signin-options a {
  color: #FCD535;
  text-decoration: none;
  font-size: 0.85em;
}

.signin-login-button {
  margin: 0.4em 0;
  background-color: #FCD535;
  color: black;
  border: none;
  border-radius: 0.45em;
  cursor: pointer;
}

.asdfghjklqwertyuiop{
  font-size: 0.9em;
  font-weight: 600;
  margin: 1.8em;
}

.signin-signup {
  color: #b0b3c7;
  font-size: 0.85em;
  margin-top: 0.5em;
  text-align: center;
}

.signin-google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border: 1px solid #d1d1d1;
  padding: 0.4em;
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 1em;
  color: #5f6368;
  font-weight: 600;
  background-color: #ffffff;
  transition: background-color 0.2s ease;
}

.signin-google-button img {
  width: 1.2em;
  height: 1.2em;
}

.signin-google-button:hover {
  background-color: #f7f7f7;
}

.signin-divider {
  display: flex;
  align-items: center;
  margin: 1.2em 0;
  color: #b0b3c7;
}

.signin-divider span {
  flex-grow: 1;
  text-align: center;
  font-size: 0.85em;
}

.signin-lottie-animation {
  margin: auto 0 auto auto;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  animation: spin 2s linear infinite;
  z-index: 20;
  position: relative;
  justify-self: center;
  display: flex
;
  margin: 0.2em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive adjustments */
@media (max-width: 956px) {
  .signin-container {
    justify-content: center;
    padding: 1em;
  }

  /* Hide Lottie animation on smaller screens */
  .signin-lottie-animation {
    display: none;
  }

  .signin-login-box {
    width: 100%;
    max-width: 28em;
    padding: 0em 1.5em 1.5em 1.5em;
    margin: auto;
  }
}



.signin-header {
  text-align: center;
  margin-bottom: 20px;
}

.signin-content {
  position: relative;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.signin-content.slide-in-right {
  transform: translateX(100%);
  opacity: 0;
  animation: slideInFromRight 0.3s forwards;
}

.signin-content.slide-in-left {
  transform: translateX(-100%);
  opacity: 0;
  animation: slideInFromLeft 0.3s forwards;
}

.signin-content.slide-out-left {
  animation: slideOutToLeft 0.3s forwards;
}

.signin-content.slide-out-right {
  animation: slideOutToRight 0.3s forwards;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideOutToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
