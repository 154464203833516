.xyza{
    position: absolute;
    z-index: 100000;
    width: 96.1em;
    height: 46em;

}

.zdgl{
    color: blue;
}