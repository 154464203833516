.add-funds-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2em;
    gap: 2em;
    max-width: 1200px;
    margin: auto;
    padding-top: 0;
  }
  
  .left-panel,
  .right-panel {
    flex: 1;
    max-width: 48%;
    padding: 1.5em;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
  }
/*   
  .label {
    font-size: 1.1em;
    margin-bottom: 0.5em;
    display: block;
    font-weight: bold;
  } */
  
  .amount-input,
  .upi-input {
    width: 100%;
    padding: 0.5em;
    font-size: 1em;
    margin-bottom: 1.2em;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .quick-add-container {
    display: flex;
    gap: 1em;
    margin-bottom: 1.2em;
  }
  
  .quick-add-button {
    flex: 1 1 45%;
    padding: 0.5em;
    font-size: 1em;
    text-align: center;
    border: 1px solid #FFA500;
    color: #FFA500;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  
  .quick-add-button:hover {
    background-color: transparent;
    color: #FFA500;
  }
  
  .deposit-button {
    width: 100%;
    padding: 0.4em;
    font-size: 1.2em;
    background-color: transparent;
    color: white;
    border: 2px solid white;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  
  /* .deposit-button:hover {
    background-color: #FFA500;
  } */
  
  .transaction-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .transaction-table th,
  .transaction-table td {
    padding: 1em;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    gap: 0.5em;
    margin-top: 1em;
  }
  
  .pagination-button {
    padding: 0.5em 1em;
    font-size: 1em;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .pagination-button.active {
    background-color: #007bff;
    color: white;
  }
  
  .ajsbdhasbdhasbdbashj {
    font-size: 25px;
  }

  .quick-add-container-mobile{
    display: none !important;
    gap: 0.5em;
  }
.quick-add-button-mobile{
    margin-bottom: 0.5em;


  flex: 1 1 45%;
    padding: 0.5em;
    font-size: 1em;
    text-align: center;
    border: 1px solid #FFA500;
    color: #FFA500;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
}
  
  /* Responsive Layout */
  @media (max-width: 1000px) {
    /* For screens below 768px */
    .add-funds-container {
      flex-direction: column;
      padding: 1.5em;
    }
  
    .left-panel,
    .right-panel {
      max-width: 100%;
    }
  
    .quick-add-container {
      gap: 0.5em;
    }
  
    .quick-add-button {
      flex: 1 1 calc(50% - 0.5em);
      font-size: 0.9em;
      padding: 0.8em;
    }
  
    .amount-input,
    .upi-input {
      font-size: 1em;
      padding: 0.6em;
    }
  
    .deposit-button {
      font-size: 1em;
      padding: 0.8em;
    }
  
    .transaction-table th,
    .transaction-table td {
      padding: 0.6em;
      font-size: 0.65em;
    }
  
    .pagination-button {
      font-size: 0.65em;
      padding: 0.5em 0.7em;
    }
    .quick-add-button-mobile{
        display: block;
        padding: 0.2em !important;
    }
  }
  
  @media (max-height: 485px) {
    .add-funds-container {
      padding: 1em;
    }

   
  
    .quick-add-button {
      font-size: 0.9em;
      padding: 0.5em;
    }
  }
  

  @media (max-width:650px) {
    .quick-add-container {
        
        display: none !important;
      }

      .quick-add-container-mobile{
        display: flex !important;
      }

      .deposit-button{
        padding: 0.6em;
    }
    
  }
  /* Overflow Handling for Many Buttons */
  @media (max-width: 450px) {
    .pagination {
      flex-wrap: nowrap;
      overflow-x: auto;
      scrollbar-width: thin; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
    }

    .deposit-button{
        padding: 0.4em;
    }
    .wallet-buttons{
        /* flex-direction: column; */
    }
    .wallet-container{
        margin: 0;
        padding: 0;
    }

    .add-funds-container,     .wallet-buttons{
        padding: 0.5em;
    }



    .pagination::-webkit-scrollbar {
      display: none; /* Chrome, Safari */
    }
  }