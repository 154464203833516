.withdraw-funds-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2em;
    gap: 2em;
    max-width: 1200px;
    margin: auto;
    padding-top: 0;
  }
  
  .left-section,
  .right-section {
    width: 48%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 1.5em;
    flex: 1 1 calc(100% - 2em); /* Allow sections to resize */
  }
  
  .account-balance {
    margin: 0;
    color: #555;
    text-align: left;
    margin-bottom: 1em;
  }
  
  .label-wallet {
    display: flex;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .amount-field,
  .upi-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .upi-section {
    margin-bottom: 20px;
  }
  
  .amount-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .withdraw-button {
    padding: 10px 20px;
    background-color: #ff0000;
    color: #fff;
    border: none;
    font-size: 17px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    align-items: start;
    transition: background-color 0.3s;
    width: 40%;
  }
  
  .right-section {
    width: 48%;
  }
  
  .transactions-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .transactions-table th {
    text-align: center;
    padding: 10px;
    font-weight: bold;
    color: #555;
  }
  
  .transactions-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .pagination-section {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    margin: 0 5px;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    color: var(--text-color);
  }
  
  .pagination-button:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .amount-input {
    width: 100%;
    padding: 10px;
    height: 5%;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s;
    padding-left: 20px;
    margin-bottom: 1em;
    margin-top: 2em;
    left: "10px";
  }
  
  .upi-input {
    width: 100%;
    padding: 10px;
    height: 5%;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s;
    padding-left: 40px; /* Space for the icon */
    left: "10px";
  }
  
  .pagination-button.active {
    background-color: #007bff;
    color: #fff;
    border-color: #FFA500;

    font-weight: bold;
  }
  
  .red-amount {
    color: red; /* Set the text color to red */
  }
  .green-amount {
    color: #4caf50; /* Set the text color to red */
  }
  
  .amount-to-withdraw {
    margin: 0;
    color: #555;
  }
  
  .left-section {
    width: 48%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 2em;
  }
  .right-section {
    width: 48%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 2em;
  }
  
  .account-balance {
    margin: 0;
    color: #555;
    text-align: left;
    margin-bottom: 2%;
  }
  
  .label-wallet {
    display: flex;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--text-color);
  }
  
  .amount-field,
  .upi-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .upi-section {
    margin-bottom: 20px;
  }
  
  .amount-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .withdraw-button {
    padding: 10px 20px;
    background-color: #ff0000;
    color: #fff;
    border: none;
    font-size: 17px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    align-items: start;
    transition: background-color 0.3s;
    width: 40%;
  }
  
  .right-section {
    width: 48%;
  }
  
  .transactions-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .transactions-table th {
    text-align: center;
    padding: 10px;
    font-weight: bold;
    color: #555;
  }
  
  .transactions-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .pagination-section {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    margin: 0 5px;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pagination-button:hover {
    background-color: #007bff;
    color: var(--text-color);
  }
  
  .amount-input {
    width: 100%;
    padding: 8px 10px 8px 8px !important;
    height: 5%;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s;
    padding-left: 20px;
    margin-bottom: 1em;
    left: "10px";
    color: var(--text-color);
    background-color: var(--bg-color);
  }
  
  .upi-input {
    width: 100%;
    padding: 8px 10px 8px 8px !important;
    height: 5%;
    font-size: 16px;
    border: 1px solid #ddd;
    margin-top: 2em;
    border-radius: 5px;
    transition: border-color 0.3s;
    padding-left: 40px; /* Space for the icon */
    left: "10px";
    color: var(--text-color);
    background-color: var(--bg-color);
  }
  
  .pagination-button.active {
    background-color: #FFA500;
    color: #fff;
    font-weight: bold;
  }
  
  .red-amount {
    color: red; /* Set the text color to red */
  }
  .green-amount {
    color: #4caf50; /* Set the text color to red */
  }
  
  .amount-to-withdraw {
    margin: 0;
    color: #555;
  }
  @media (max-width: 768px) {
    .withdraw-funds-container {
      flex-direction: column;
      padding: 1em;
      gap: 1em;
    }
  
    .left-section,
    .right-section {
      width: 100%;
    }
  
    .account-balance {
      font-size: 16px;
    }
  
    .label-wallet {
      font-size: 14px;
    }
  
    .amount-input,
    .upi-input {
      font-size: 14px;
      padding: 8px;
    }
  
    .withdraw-button {
      width: 100%;
      font-size: 15px;
    }
  
    .transactions-table {
      font-size: 14px;
    }
  
    .transactions-table th,
    .transactions-table td {
      font-size: 14px;
      padding: 8px;
    }
  
    .pagination-button {
      font-size: 12px;
      padding: 6px 10px;
    }
  }
  
  @media (max-width: 480px) {
    .withdraw-funds-container {
      padding: 0.5em;
    }
  
    .left-section,
    .right-section {
      padding: 1em;
    }
  
    .account-balance {
      font-size: 14px;
    }
  
    .label-wallet {
      font-size: 12px;
    }
  
    .amount-input,
    .upi-input {
      font-size: 12px;
      padding: 6px;
    }
  
    .withdraw-button {
      padding: 8px 16px;
      font-size: 13px;
    }
  
    .transactions-table {
      font-size: 12px;
    }
  
    .transactions-table th,
    .transactions-table td {
      font-size: 12px;
      padding: 6px;
    }
  
    .pagination-button {
      font-size: 10px;
      padding: 5px 8px;
    }
  }
  
  @media (max-width: 1089px) {
    .amount-summary{
      display: flex;
      flex-direction: column;
    }
    .withdraw-button{
      width: 100%;
    }
    .right-section{
      font-size: 1em;
    }
  }