.ExecutedTrades{
    position: absolute;
    width: 100%;
    background-color: var(--bg-color);

}

.ExecutedTradeRow{
    background-color: var(--bg-color);
    padding: 1.25em;
    border-radius: 0.5em;
    box-shadow: var(--box-shadow);
}

.scrollable-table-container {
    overflow-y: auto;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  
  .scrollable-table {
    margin-bottom: 0;
    border-collapse: collapse;
  }
  
  .scrollable-table thead {
    position: sticky;
    top: 0;
    background-color: white;
    /* z-index: 1;   */
  }

  .Executed-buttons{
    justify-content: space-around;
  }
  

.tqazxcvbn{
    background-color: var(--text-color) !important;
}

.ExecutedTradeOptions{
    background-color: var(--bg-color);
    color: var(--text-color);
}

.ExecutedTradeSelect{
    color: var(--text-color) !important;
}

.ExecutedTradeSelect:focus{
    border-color: #666; /* Change border color on focus */
    box-shadow: none; /* Remove the blue shadow */
    outline: none;
}

@media (max-Width:48em) {
    .Executed-buttons{
        width: 100%;
        text-align: center;
        align-items: center;
        gap: 1em !important;
    }

    .Executed-Download-btn{
        justify-content: center ;
    }

    .Broker-id-Executed{
        margin-bottom: 0.5em;
    }
}