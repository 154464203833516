.mobile-calender-paper{
    flex-direction: row;
    gap: 5em;
}

.mobile-year-month{
    display: none;
}


@media (max-width:769px) {
    .mobile-calender-paper{
        justify-content: space-between;
        flex-direction: column;
        gap: 1em;
        align-items: start;
        
    }   
    .mobile-year-month{
        display: flex;
        gap: 2em;
    }

    .qazxcrgh{
        display: none;
    }

    .wwwwsssssdddd{
        display: block;
    }
}

@media (max-width:600px) {
    .mobile-calender-paper{
        flex-direction: column;

    }   
}