.container-abcefgh {
  display: flex;
  flex-direction: row;
  gap: 1em;
  border-radius: 0.75em; /* 12px */
  padding: 1em;
}

.card-wrapper-xyz {
  border-radius: 0.75em; /* 12px */
  box-shadow: 0 0.25em 0.75em rgba(0, 0, 0, 0.1); /* 4px, 12px */
  padding: 1.875em; /* 30px */
  width: 100%;
  text-align: left;
  margin-bottom: 1em;
}
.card-wrapper-xyz2 {
  display: flex;
  border-radius: 0.75em;
  box-shadow: 0 0.25em 0.75em rgba(0, 0, 0, 0.1);
  padding: 1em;
  width: 50%;
  text-align: left;
  margin-bottom: 1em;
  gap: 2em;
  flex-direction: column;
}

.ahsdvbghasvdhgas {
  margin-right: 1em;
}

.ahsbfghsdvbfgshdvbgfhd {
  margin-block: 1em;
}

.image-reffer-aksjdb {
  height: 60%;
  width: 100%;
}

.withdrawable-text-kahbsdhsjd {
  font-family: Roboto, sans-serif;
  font-size: 1em;
  color: var(--text-color);
  border-radius: 8px;
  text-align: center;
  margin: 20px auto;
}

.shareclass-12345 {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 10px;
  border: 1px solid gray;
}

.copybtn12 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.refferId-123456 {
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-inline: 1em;
}

.shareajsvd-123 {
  display: flex;
  align-items: end;
  justify-content: end;
  margin: auto;
  margin-right: 0;
  padding: 0%;
}

.mainrefferbox {
  display: flex;
  flex-direction: column;
  border-radius: 0.75em; /* 12px */
  box-shadow: 0 0.25em 0.75em rgba(0, 0, 0, 0.1); /* 4px, 12px */
  padding-top: 5%;
  padding-left: 5%;
  padding-bottom: 0.5em;
}
.referboxclass {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fef7ea;
  width: 12%;
  height: auto;
  border-radius: 50%;
}
.referboxclass2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008f75;
  width: 12%;
  height: auto;
  border-radius: 50%;
}

.iconajgsvdhgasd {
  margin: 0.5em;
}

.askbdashdahsbdabsdhasbdhasd {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #25d366;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.asdfvbnm {
  font-size: 0.8em;
  white-space: nowrap;
}

@media (max-width: 1025px) {
  .container-abcefgh {
    flex-direction: column;
  }

  .card-wrapper-xyz2 {
    flex-direction: row;
    width: 100%;
  }
  .mainrefferbox {
    width: 50%;
  }
  .iconajgsvdhgasd {
    margin: 0.4em;
  }
}

@media (max-width: 700px) {
  .iconajgsvdhgasd {
    font-size: 0.8em;
    margin: 0.5em;
  }
}
@media (max-width: 600px) {
  .iconajgsvdhgasd {
    margin: 0.2em;
  }
}

@media (max-width: 490px) {
  .poiuytgbnkl {
    font-size: 0.8em;
  }
  .asdfvbnm {
    display: none;
  }
  .card-wrapper-xyz2 {
    flex-direction: column;
    width: 100%;
  }
  .mainrefferbox {
    width: 100%;
  }
  .shareajsvd-123 {
    width: 30%;
  }
  .iconajgsvdhgasd {
    font-size: 1em;
    margin: 0.5em;
  }
  .referboxclass2 {
    width: 20%;
  }
  .referboxclass {
    width: 20%;
  }
  .ahsbfghsdvbfgshdvbgfhd {
    font-size: 0.8em;
  }
  .asdfghjiujnbvdfghjk {
    font-size: 0.8em;
  }
  .ahsdvbghasvdhgas {
    font-size: 1em;
  }
  .asdwedfghjmk {
    font-size: 0.8em;
  }
}

@media (max-width: 375px) {
  .poiuytgbnkl {
    font-size: 0.7em;
  }
  .copybtn12 {
    font-size: 0.6em;
  }
  .askbdashdahsbdabsdhasbdhasd {
    padding: 0.7em;
  }
  .refferId-123456 {
    margin-inline: 0.5em;
  }
}