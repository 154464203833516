.kjhuk{
    font-size: 18px;
}

.hdfhj{
    color: rgb(0, 0, 0);
    font-weight: 500;
    margin-block: 10px;
    padding-block: 5px;
    font-size: 20px;
    background-color: rgb(237, 237, 237);
    border-radius: 20px;
}

.adsdas{
    border-bottom: 2px solid blue;
}

/* dark */

.jhsbdchjbdcbs {
    margin-top: 3em;
    border-radius: 1em;
    background-color: black;
    box-shadow: rgba(255, 255, 255, 0.15) 0px 5px 15px 0px;
}

.jhcbhjdbdcjbsdjcbjhs {
    color: white;
    font-weight: 500;
    margin-block: 10px;
    padding-block: 5px;
    font-size: 20px;
    background-color: rgb(16, 16, 16);
    border-radius: 20px;
}

.hasbchjbasbhjabjh {
    color: white;
    font-size: 18px;
}