/* General container styling */
.marketplace-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.marketplace-title {
  text-align: center;
  color: #333;
  margin-bottom: 1.5rem;
}

/* Form styling */
.marketplace-form {
  display: flex;
  flex-direction: column;
}

.marketplace-input {
  padding: 0.8rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

/* Textarea specific styling */
.marketplace-textarea {
  resize: vertical;
  height: 100px;
}

/* Button styling */
.marketplace-button {
  background-color: #007bff;
  color: #fff;
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.marketplace-button:hover {
  background-color: #0056b3;
}

/* Responsive design */
@media (max-width: 600px) {
  .marketplace-container {
    padding: 1rem;
  }
}
