.features-section {
  background-color: #111111;
  color: #ffffff;
  text-align: center;
  padding: 60px 20px;
}

.features-title {
  font-size: 2.8em;
  margin-bottom: 10px;
  color: #ffffff;
}

.features-subtitle {
  font-size: 1em;
  margin-bottom: 40px;
  color: #dddddd;
}

.features-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 4em !important;
}

.feature-card {
  position: relative;
  padding-top: 50px;
  background: #222222;
  border-radius: 10px;
  padding: 20px;
  width: 17em;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-icon {
  position: absolute;
  top: -3em; /* Move the image up to overlap */
  left: 50%;
  transform: translateX(-50%);
  width: 104px;
  height: 100px;
  border-radius: 50%; /* Optional: circular image */

  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.feature-title {
  font-size: 1.5em;
  margin-top: 2em; /* Adjust to give space below the overlapping icon */
  color: #ffa700;
}

.feature-description {
  font-size: 1em;
  color: #cccccc;
}
