.profile {
  display: flex;
}

.profile-sidebar {
  background-color: var(--bg-color);
  color: var(--text-color);
  padding: 6em 20px 20px 20px;
  position: fixed;
  height: 100vh; /* Make sidebar full height */
  /* margin-top: 5em; */
  overflow: hidden;
  box-shadow: var(--box-shadow);
  width: 275px; /* Default width with text */
  transition: width 0.3s ease;
}

.profile-right-ajsndajskd {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0 0.5em;
}

.react-switch-bg {
  border-radius: 5px !important;
}

.react-switch-handle {
  border-radius: 20% !important;
}

.wallet-container {
  border-radius: 0.75em; /* 12px */
}

.wallet-buttons {
  display: flex;
  width: 50%;
  padding-block: 2em;
  padding-inline: 3em;
  align-items: start;
}

.user-info {
  width: 100%;
  text-align: left;
  display: flex;
  gap: 2em;
  flex-direction: row;
}

.user-info-first,
.user-info-second {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-second {
  width: 100%;
  margin-top: 6em;
  padding: 0em 1em 1em 1em;
  margin-left: 275px;
}

.profile-right-first {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 2.5em 2.5em 2.5em 1em;
  box-shadow: var(--box-shadow);
  border-radius: 0.3em;
}
.profile-right-first-asdhasdhas {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 2.5em;
  box-shadow: var(--box-shadow);
  border-radius: 0.3em;

}

.profile-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-item {
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.transaction-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.transaction-item > div {
  flex: 1;
  text-align: center;
}

.transaction-name,
.transaction-date,
.transaction-invoice-id,
.transaction-amount,
.transaction-status,
.transaction-details {
  padding: 5px 10px;
}

.transaction-amount.negative {
  color: red;
}

.transaction-amount.positive {
  color: green;
}

.transaction-status.receive {
  color: green;
}

.transaction-status.transfer {
  color: orange;
}

.transaction-header {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  font-weight: bold;
  margin-bottom: 10px;
}

.header-name,
.header-date,
.header-invoice-id,
.header-amount,
.header-status {
  padding: 5px;
}
.transaction-status.payment {
  color: red;
}

.transaction-status.withdraw {
  color: blue;
}

.transaction-status.deposit {
  color: purple;
}

.sidebar-item.active {
  background-color: orange; /* Highlight active item */
  color: white;
}

.badge {
  background-color: orange;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 12px;
  margin-left: 5px;
}

.profile-sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.sidebar-icon {
  font-size: 20px;
  margin-right: 10px;
}

.sidebar-text {
  font-size: 16px;
}


.sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 10px;
  cursor: pointer;
}

.sidebar-icon {
  font-size: 20px;
  margin-right: 10px;
}

.sidebar-text {
  font-size: 16px;
  display: inline-block;
  text-align: start;
}
.user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ccc; /* Placeholder color if no image */
  object-fit: cover;
  margin-right: 17px; /* Space between image and user details */
}

.ajshdhjabdasbdjsdaawdaw {
  width: 36%;
}

.ajsdgagdawtgdtyawgtyd {
  margin: 0%;
  color: #676b72;
  font-size: .75rem;
  line-height: 12px;
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */

}




@media (max-width: 876px) {
  .profile-sidebar {
    width: 5em; /* Reduced width for mobile */
  }

  .profile-second {
    margin-left: 5em;
  }

  .client-id-profile{
    font-size: 13px !important;
  }

  .profile-second{
    padding: 0;
  } 

  .wallet-container{
    padding: 0;
    margin: 0;
  }

  .theme-toggle-switch {
    display: none !important;
  }
  .user-info-first,
  .user-info-second {
    flex-direction: column;
  }

  .wxobt {
    width: 100% !important;
  }

  .user-info {
    gap: 1em;
  }
  .sidebar-text {
    display: none; /* Hide text on mobile */
  }

  .sidebar-icon {
    font-size: 17px;
  }
}

@media (max-width: 650px) {
  .profile-right-first {
    flex-direction: column;
  }

  .profile-right-first-asdhasdhas {
    padding: 1.5em 1em;
  }
}
@media (max-width: 484px) {
  .wallet-button {
    font-size: 20px;
  }
  .user-info{
    flex-direction: column !important;
  }
}

@media (max-width: 876px) {
  .wallet-buttons {
    flex-direction: column;
  }

  .wallet-button {
    width: 100%;
    padding: 1em;
  }

  .profile-sidebar {
    width: 5em;
  }

  .user-info-first,
  .user-info-second {
    flex-direction: column;
  }

  .sidebar-text {
    display: none;
  }

  .sidebar-icon {
    font-size: 17px;
  }
}

@media (max-width: 1092px) {
  

  .wallet-container {
    padding: 0.5em;
    margin: 0.5em;
  }

  .wallet-button {
    width: 100% !important;
    margin: 1em;
  }
  .wallet-buttons {
    width: 100%;
    padding: 0;
    display: flex;
    padding-block: 1em;
    padding-inline: 1em;
    gap: 0.5em;
    flex-direction: row;
    font-size: 1em;
  }
}
@media (max-width: 650px) {
  .profile-right-first {
    flex-direction: row;
    box-shadow: none;
    padding: 0.5em 0em 0.5em 1em;
  }

  .wallet-container {
    padding: 0.5em;
    margin: 0.5em;
  }

  .wallet-button {
    width: 100% !important;
    margin: 1em;
    font-size: 13px;
  }
  .wallet-buttons {
    width: 100%;
    padding: 0;
    display: flex;
    padding-block: 1em;
    padding-inline: 1em;
    gap: 0.5em;
    flex-direction: row;
    font-size: 15px;
  }
}
@media (max-width: 320px) {
 
  .profile-right-first {
    flex-direction: column;
  }

  .wallet-button {
    font-size: 11px;
  }

  .wallet-container {
    padding: 0.5em;
    margin: 0.5em;
  }

  .wallet-button {
    width: 100% !important;
    margin: 1em;
  }
  .wallet-buttons {
    width: 100%;
    padding: 0;
    padding-block: 1em;
    padding-inline: 1em;
    gap: 0.5em;
    font-size: 10px;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 410px) {
  

  .wallet-container {
    padding: 0.5em;
    margin: 0.5em;
  }

  .wallet-button {
    width: 100% !important;
    margin: 1em;
  }
  .wallet-buttons {
    width: 100%;
    padding: 0;
    padding-block: 1em;
    padding-inline: 1em;
    gap: 0.5em;
    font-size: 14px !important;
    display: flex;
    flex-direction: row;
  }
}