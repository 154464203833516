/* Menubar container */
.menubar-container {
  position: relative;
  background-color: #111111;
  color: white;
  padding: 0 1em;
}

/* Menu styling */
.menu {
  display: flex;
  list-style-type: none;
  margin-top: 1em;
  font-size: 17px;
  z-index: 2;
}

.menubar {
  align-items: center;
  justify-content: space-between;
}

/* hamburger2 icon styling */
.hamburger2 {
  display: none;
  font-size: 2em;
  margin-top: 0.3em;
  cursor: pointer;
  color: white;
}

/* Menu items */
.menubar-logo img {
  width: 4.25em;
  height: 3.5em;
}

.menu > li {
  color: white;
}

.link {
  margin-right: 1.1em;
  border-right: 2px solid #ffa700;
  padding: 0 1em;
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 768px) {
  .menu {
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    background-color: #111111;
    width: 100%;
    padding: 0 0 2em 0;
    align-items: center;
    border-bottom: 1px solid gray;
    border-radius: 1em;
    display: none;
  }

  .menu-open {
    display: flex;
    animation: slideIn 0.8s forwards;
  }

  .menu-close {
    animation: slideOut 0.8s forwards;
  }

  .hamburger2 {
    display: block;
    margin-right: 0.5em;
    font-size: 1.8em;
  }

  .link {
    margin: 0.5em 0;
    border: none;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}
