.LiveTrade{
    position: absolute;
    width: 100%;
}

.table-container {
    width: 100%;
    overflow-x: auto;
    margin-top: 5em;

  }
  
  .livetrade-table {
    width: 100%;
    border-collapse: collapse;
    background-color: transparent;
    color: #ffffff;
    font-family: Arial, sans-serif;
    margin: 20px 0;
  }
  
  th, td {
    padding: 10px;
    text-align: center;
    border-top: 1px solid #333333; /* Top border */
    border-bottom: 1px solid #333333; /* Bottom border */
  }
  
  thead {
    background-color: #2b2b2b;
  }
  
  .buy-button, .sell-button {
    margin: 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .buy-button {
    background-color: #00b894;
    color: white;
  }
  
  .sell-button {
    background-color: #d63031;
    color: white;
  }
  
  .actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 5px;
  }
  
  .stock-name {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  