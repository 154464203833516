.tyhgcv {
  margin-top: -500px;
}

.sub-model {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: var(--bg-color);
  /* boxshadow: 24; */
  border: 1px solid var(--text-color);
  padding-top: 2em;
  padding-bottom: 4em;
  color: var(--text-color);
  border-radius: 10px;
  padding-left: 2em;
  padding-right: 2em;
}

.deploy-btn:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.no-strategy-message{
    width: 100%;
    border: 2px solid var(--text-color);
    padding: 1em;
}

