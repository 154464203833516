/* Base styles */
.signup-container {
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #1a1a1a;
  height: 100vh;
}

.otp-input {
  width: 40px;
  background-color: transparent;
  height: 50px;
  color: white;
  margin: 5px;
  font-size: 18px;
  text-align: center;
  border: 2px solid #ddd;
  border-radius: 8px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.ajsndjkasndkjasndjkasnkjd:hover, .ajsndjkasndkjasndjkasnkjd {
  color: #FCD535;
}

.otp-input:focus {
  border-color: #FCD535;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.otp-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.signup-box {
  width: 28em;
  padding:0  1.75em 1em 1.75em;
  background-color: #1a1a1a;
  border-radius: 0.5em;
  text-align: start;
  color: #ffffff;
  box-shadow: 0 0.125em 0.625em rgba(0, 0, 0, 0.2);
  margin: auto;
  margin-left: 10em;
}

h2 {
  font-size: 1.5em;
}

.asadfsjdnkjvd {
  display: flex;
  flex-direction: column;
  width: 100%;
}

form {
  display: flex;
  flex-direction: column;
}

/* Input fields */
.signup-label {
  font-size: 0.9em;
  color: #b0b3c7;
  margin-bottom: 0.3em;
}

.signup-input {
  width: 100%;
  max-width: 100%; /* Keep it from resizing in responsive layouts */
  padding: 0.7em;
  border-radius: 0.45em;
  border: 0.08em solid #3b3e57;
  background-color: transparent;
  color: #ffffff;
  font-size: 0.85em;
  margin-bottom: 1.3em;
}

/* Other elements */
.password-container {
  position: relative;
}

.show-password {
  position: absolute;
  right: 0.9em;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #b0b3c7;
  font-size: 0.85em;
  cursor: pointer;
}

.options {
  display: flex;
  justify-content: space-between;
  font-size: 0.85em;
  color: #b0b3c7;
}

.options a {
  color: #FCD535;
  text-decoration: none;
  font-size: 0.85em;
}

.signup-button {
  line-height: 0.6em;
  margin: 0.4em 0;
  background-color: #FCD535;
  color: black;
  border: none;
  padding: 0.8em;
  border-radius: 0.45em;
  cursor: pointer;
  font-size: 1.05em;
  font-weight: 800;
}

.signup {
  color: #b0b3c7;
  font-size: 0.85em;
  margin-top: 0.5em;
  text-align: center;
}

.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border: 1px solid #d1d1d1;
  padding: 0.4em;
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 1em;
  color: #5f6368;
  font-weight: 600;
  background-color: #ffffff;
  transition: background-color 0.2s ease;
}

.google-button img {
  width: 1.2em;
  height: 1.2em;
}

.google-button:hover {
  background-color: #f7f7f7;
}

.signup a {
  color: #FCD535;
  text-decoration: none;
  font-size: 0.85em;
}

.divider {
  display: flex;
  align-items: center;
  margin: 1.2em 0;
  color: #b0b3c7;
}

.divider span {
  flex-grow: 1;
  text-align: center;
  font-size: 0.85em;
}

.lottie-animation {
  margin: auto auto auto 0;
}

/* Responsive adjustments */
@media (max-width: 956px) {
  .signup-container {
    justify-content: center;
    padding: 1em;
  }

  /* Hide Lottie animation on smaller screens */
  .lottie-animation {
    display: none;
  }

  .signup-box {
    width: 100%;
    max-width: 28em;
    padding: 1.5em;
    margin: auto;
  }
}



/* animation */

/* .signup-content {
  position: relative;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
 */
.signup-content.slide-in-left {
  transform: translateX(-100%);
  opacity: 0;
  animation: slideInFromLeft 0.3s forwards;
}

.signup-content.slide-in-right {
  transform: translateX(100%);
  opacity: 0;
  animation: slideInFromRight 0.3s forwards;
}

.signup-content.slide-out-left {
  animation: slideOutToLeft 0.3s forwards;
}

.signup-content.slide-out-right {
  animation: slideOutToRight 0.3s forwards;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideOutToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
